import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SearchService } from '../shared/searchservice';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { OktaAuthService } from '@okta/okta-angular';
import { SessionService } from '../shared/sessionservice';

@Component({
  selector: 'app-search-component',
  templateUrl: './search.component.html'
})
export class SearchComponent {
  claimControlNumber: Number = 0;
  serviceResult: any;
  searchForm: FormGroup;
  constructor(private searchService: SearchService, private router: Router,
              @Inject(DOCUMENT) private document: Document, private _oktaAuthService: OktaAuthService,
              private sessionService: SessionService) {

  }

  ngOnInit() {
    this.sessionService.userOrg = this._oktaAuthService.authStateManager._authState.idToken?.claims.orgval;
    this.sessionService.userId = this._oktaAuthService.authStateManager._authState.idToken?.claims.email ?? '';
    
    this.searchForm = new FormGroup({
      controlNumber: new FormControl(null), //note, can have up to 3 Constructor Params: default value, validators, AsyncValidators
      claimRefNumber: new FormControl(null),
      policyNumber: new FormControl(null),
      insuredName: new FormControl(null),
      contactFirstName: new FormControl(null),
      contactLastName: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      transmitted: new FormControl(null),
      received: new FormControl(null),
      saved: new FormControl(null),
      verified: new FormControl(null),
      approved: new FormControl(null),
      pending: new FormControl(null),
      rejected: new FormControl(null),
      finalized: new FormControl(null)
    })
  }

  reset() {
    this.searchForm.patchValue({
      controlNumber: null,
      claimRefNumber: null,
      policyNumber: null,
      insuredName: null,
      contactFirstName: null,
      contactLastName: null,
      startDate: null,
      endDate: null,
      transmitted: false,
      received: false,
      saved: false,
      verified: false,
      approved: false,
      pending: false,
      rejected: false,
      finalized: false
    });
  }

  onSubmit() {
    let claimSearch = {
      userId: this.sessionService.userId,
      userOrg: this.sessionService.userOrg,
      controlNumber: this.searchForm.get('controlNumber').value ?
        Number(this.searchForm.get('controlNumber').value) : 0,
      referenceNumber: this.searchForm.get('claimRefNumber').value ? this.searchForm.get('claimRefNumber').value : '',
      policyNumber: this.searchForm.get('policyNumber').value,
      insuredName: this.searchForm.get('insuredName').value,
      contactFirstName: this.searchForm.get('contactFirstName').value,
      contactLastName: this.searchForm.get('contactLastName').value,
      receivedDateStart: this.searchForm.get('startDate').value,
      receivedDateEnd: this.searchForm.get('endDate').value,
      transmitted: this.getTransmittedValue(this.searchForm.get('transmitted').value),
      status: this.getStatusSearch()
    };
    this.searchService.search(claimSearch).subscribe(response => {
      if ((<Response>response).result !== null) {
        sessionStorage.setItem('lastSearchResult', JSON.stringify(response));
        this.router.navigate(['/searchresult'], { state: { searchResult: response } });
      }
      else {
        console.log('No results');
      }
    });
  }

  getTransmittedValue(value){
    if(value === null)
      return null;

    if(value === 'No')
      return false;

    if(value === 'Yes')
      return true;
  }

  getStatusSearch(): any[] {
    //console.log(this.searchForm.get('received'));
    let status = [];
    if (this.searchForm.get('received').value != null && this.searchForm.get('received').value)
      status.push('Received');
    if (this.searchForm.get('saved').value != null && this.searchForm.get('saved').value)
      status.push('Saved');
    if (this.searchForm.get('verified').value != null && this.searchForm.get('verified').value)
      status.push('Verified');
    if (this.searchForm.get('approved').value != null && this.searchForm.get('approved').value)
      status.push('Approved');
    if (this.searchForm.get('pending').value != null && this.searchForm.get('pending').value)
      status.push('Pending');
    if (this.searchForm.get('rejected').value != null && this.searchForm.get('rejected').value)
      status.push('Rejected');
    if (this.searchForm.get('finalized').value != null && this.searchForm.get('finalized').value)
      status.push('Finalized');

    if (status.length > 0)
      return status;
    else
      return null;
  }

  newclaim() {
    this.router.navigate(['/newclaim']);
  }
}

export class Response {
  result: any;
  errorMessage: string;
  timeGenerated: string;
}
