import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-claimheader-component',
  templateUrl: './claimheader.component.html',
  styleUrls: ['claimheader.component.css']
})
export class ClaimHeaderComponent {
  @Input() claimheader: any;
  @Input() openClaimsMessage: string;
  constructor() {

  }

  ngOnInit() {
    //console.log('CLaim Header; ' + JSON.stringify(this.claimheader));
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
  }
}
