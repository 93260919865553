import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: `
  <button type="button" class="btn" (click)="deleteReserve($event)" >
                                        <i class="fa fa-lg fa-trash" style="color:red"></i>
                                    </button>`
})
export class ReserveDeleteRendererComponent implements AgRendererComponent {
  params: any;
  agInit(params: any): void {
    this.params = params;
    //console.log('Ag Grid: ' + JSON.stringify(this.params.data, this.getCircularReplacer()));
  }

  deleteReserve(event){
    this.params.context.componentParent.reserveGridApi.applyTransaction({ remove: [this.params.node.data] }) ;
      let claimReserve = this.params.context.componentParent.claimReserves[this.params.rowIndex];
      if(claimReserve !== undefined){
        this.params.context.componentParent.claimReserves.splice(this.params.rowIndex, 1);
      }
    }

  refresh(params: any): boolean {
    return false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
}
