import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class ClaimZipCodeService extends ServiceBase {
  claimPolicyServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.claimPolicyServiceUrl = this.apiBaseUrl +  'claimzipcode/';
  }

  put(claimId, zipcode){
    return this.http.put(this.claimPolicyServiceUrl, {'claimId': claimId, 'zipcode' : zipcode});
  }
}
