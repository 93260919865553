import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import{ServiceBase} from './servicebase'
@Injectable()
export class TransmitService extends ServiceBase {
  transmitServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.transmitServiceUrl = this.apiBaseUrl + 'transmit';
  }
  
  getTransmitInfo(input) {
    return this.http.post(this.transmitServiceUrl, input).subscribe(response => {
    });
  }
}
