<div class="row">
  <app-claimheader-component *ngIf="serviceResult !== undefined" [claimheader]="serviceResult.header"
      [openClaimsMessage]="serviceResult.openClaimsMessage"></app-claimheader-component>
</div>
<div class="row">
  <div class="col-md-4">
    <app-claimsidebar-component *ngIf="serviceResult !== undefined" [claimId]="serviceResult.header.controlNumber" [claimScreens]="serviceResult.screens" (screenId)="displayScreen($event)" (sentReportOfLoss)="onSendReportOfloss($event)"></app-claimsidebar-component>
  </div>
  <div class="col-md-8">
    <app-claimscreendetail-component *ngIf="serviceResult !== undefined" [claimId]="serviceResult.header.controlNumber" [claimScreenDetail]="selectedScreen" 
    [claimStatus]="claimStatus" [claimStatusReason]="claimStatusReason" [subCompanyCode]="subCompanyCode" (saveScreen)="refreshScreen()" (claimTransmitted)="refreshScreen($event)" 
    (claimSaved)="onSaveTransmitDetails($event)"
    (sentAck)="sentAcknowledgement($event)" (documentDeleted)="refreshScreen()" 
    (lossDateUpdated)="refreshScreen()" (cantBeTransmitted)="onCantBeTransmitted($event)"
    (policyNumberUpdated)="refreshScreen($event)" (zipCodeUpdated)="refreshScreen($event)"
    [openClaimsMessage]="serviceResult.openClaimsMessage"></app-claimscreendetail-component>
  </div>
</div>
<jw-modal id="custom-modal-1">
  <div class="card text-center">
      <h4 class="card-header">{{modalHeader}}</h4>
    <div class="card-body">
      <p class="card-title">{{modalText}}</p>
    </div>
      <div class="card-footer">
        <button class="btn btn-primary" (click)="closeModal()">Close</button>
        </div>
  </div>
</jw-modal>
