<div class="row pb-5">
  <div class="col-md-12 bg-light">
    <h3>Select Search Criteria</h3>
  </div>
  <div class="col-md-12">
    <hr />
    </div>
  </div>
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
      <label for="inputControlNumber" class="col-sm-4 col-form-label">Reference Number:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="inputControlNumber"
               placeholder="Reference Number" formControlName="controlNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputRefNumber" class="col-sm-4 col-form-label">Claim Number:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="inputRefNumber"
               placeholder="Claim Number" formControlName="claimRefNumber">
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPolicyNumber" class="col-sm-4 col-form-label">Policy Number:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="inputPolicyNumber"
               placeholder="Policy Number" formControlName="policyNumber">
      </div>
    </div>

    <div class="form-group row">
      <label for="inputInsuredName" class="col-sm-4 col-form-label">Insured Name:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="inputInsuredName"
               placeholder="Insured Name" formControlName="insuredName">
      </div>
    </div>

    <div class="form-group row">
      <label for="inputContactFirstName" class="col-sm-4 col-form-label">Contact First Name:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="inputContactFirstName"
               placeholder="Contact First Name" formControlName="contactFirstName">
      </div>
    </div>

    <div class="form-group row">
      <label for="inputContactLastName" class="col-sm-4 col-form-label">Contact Last Name:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="inputContactLastName"
               placeholder="Contact Last Name" formControlName="contactLastName">
      </div>
    </div>

    <div class="form-group row">
      <label for="inputReceivedDate" class="col-sm-4 col-form-label">Received Date:</label>
      <div class="col-sm-1">
        <label for="inputStartDate" class="col-sm-2 col-form-label">Start</label>
      </div>
      <div class="col-sm-3">
        <input type="date" class="form-control" id="inputStartDate"
               placeholder="Start" formControlName="startDate">
      </div>
      <div class="col-sm-1">
        <label for="inputEndDate" class="col-sm-2 col-form-label">End</label>
        </div>
        <div class="col-sm-3">
          <input type="date" class="form-control" id="inputEndDate"
                 placeholder="End" formControlName="endDate">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-4">Transmitted:</div>
        <div class="col-sm-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="transmitted" id="rbTransmittedYes" value="Yes" />
            <label class="form-check-label" for="rbTransmittedYes">Yes</label>
          </div>          
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="transmitted" id="rbTransmittedNo" value="No" checked />
            <label class="form-check-label" for="rbTransmittedNo">No</label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12">Current Status:</div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkReceived" formControlName="received">
            <label class="form-check-label" for="chkReceived">
              Received
            </label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkSaved" formControlName="saved">
            <label class="form-check-label" for="chkSaved">
              Saved
            </label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkVerified" formControlName="verified">
            <label class="form-check-label" for="chkVerified">
              Verified
            </label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkApproved" formControlName="approved">
            <label class="form-check-label" for="chkApproved">
              Approved
            </label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkPending" formControlName="pending">
            <label class="form-check-label" for="chkPending">
              Pending
            </label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkRejected" formControlName="rejected">
            <label class="form-check-label" for="chkRejected">
              Rejected
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="chkFinalized" formControlName="finalized">
            <label class="form-check-label" for="chkFinalized">
              Finalized
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-10">
          <button type="submit" class="btn btn-primary">Search</button>
          <button type="button" class="btn btn-primary ml-3" (click)="reset()">Reset</button>
          <button type="button" class="btn btn-primary ml-3" (click)="newclaim()">New Claim</button>
        </div>
      </div>
</form>
