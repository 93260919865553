import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: `
  <input type="text" class="form-control form-control-sm"  (change)="onAmountChange($event)" [(ngModel)]="amount"/>          `
})
export class ReserveAmountRendererComponent implements AgRendererComponent {
  params: any;
  amount:Number;
  agInit(params: any): void {
    this.params = params;
    this.amount = this.params.data.amount;
    //console.log('Ag Grid: ' + JSON.stringify(this.params.data, this.getCircularReplacer()));
  }

  onAmountChange(event){
    //console.log('parent grid: ' + JSON.stringify(this.params.context.componentParent.claimId,  this.getCircularReplacer())); 
    let claimReserve = this.params.context.componentParent.claimReserves[this.params.rowIndex];
    if(claimReserve !== undefined){
      claimReserve.reserveAmount = this.amount;
    }
    else{
      this.params.context.componentParent.claimReserves[this.params.rowIndex] = {reserveAmount: this.amount};
    }
    //console.log('event: ' + JSON.stringify(this.params.context.componentParent.claimReserves,  this.getCircularReplacer())); 
  }

  refresh(params: any): boolean {
    return false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
}
