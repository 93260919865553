import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: `
  <select style="width: 100%;" (change)="onTypeChange($event)" [(ngModel)]="reserveType">
      <option *ngFor="let reserveType of reserveTypes" [ngValue]="reserveType.id">{{reserveType.description}}</option>
    </select>
          `
})
export class ReserveTypeRendererComponent implements AgRendererComponent {
  params: any;
  reserveTypes:Array<Object>;
  reserveType:any;
  agInit(params: any): void {
    this.params = params;
    this.reserveTypes = params.data.reserveType;
    this.reserveType = params.data.reserveTypeId;
    //console.log('Ag Grid: ' + JSON.stringify(this.params.data, this.getCircularReplacer()));
  }

  onTypeChange(event){
    //console.log('parent grid: ' + JSON.stringify(this.params.context.componentParent.claimId,  this.getCircularReplacer())); 
    let claimReserve = this.params.context.componentParent.claimReserves[this.params.rowIndex];
    if(claimReserve !== undefined){
      claimReserve.reserveTypeId = this.reserveType;
    }
    else{
      this.params.context.componentParent.claimReserves[this.params.rowIndex] = {reserveTypeId: this.reserveType};
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
}
