import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: `
  <select style="width: 100%;" (change)="onCovChange($event)" [(ngModel)]="reserveCoverage">
      <option *ngFor="let reserveCoverage of reserveCoverages" [ngValue]="reserveCoverage.id">{{reserveCoverage.description}}</option>
    </select>
          `
})
export class ReserveCoverageRendererComponent implements AgRendererComponent {
  params: any;
  reserveCoverages:Array<Object>;
  reserveCoverage:any;
  agInit(params: any): void {
    this.params = params;
    this.reserveCoverages = this.params.context.componentParent.reserveCoverages;
    this.reserveCoverage = this.params.data.coverageTypeId + '-' + this.params.data.uwRiskId;
    //console.log('Ag Grid: ' + JSON.stringify(this.params.data, this.getCircularReplacer()));
  }

  onCovChange(event){
    //console.log('parent grid: ' + JSON.stringify(this.params.context.componentParent.claimId,  this.getCircularReplacer())); 
    let claimReserve = this.params.context.componentParent.claimReserves[this.params.rowIndex];
    let coverageParts = this.reserveCoverage.split('-');
    if(claimReserve !== undefined){
      claimReserve.coverageTypeId = coverageParts[0];
      claimReserve.uwRiskId = coverageParts[1];
    }
    else{
      this.params.context.componentParent.claimReserves[this.params.rowIndex] = {coverageTypeId: coverageParts[0], uwRiskId: coverageParts[1]};
    }
  }

  refresh(params: any): boolean {
    //console.log('refresh coverages');
    return false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
}
