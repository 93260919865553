import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class CreateNewClaimService extends ServiceBase {
    createNewClaimServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.createNewClaimServiceUrl = this.apiBaseUrl +  'createnewclaim';
  }

  create(createNewClaimModel){
    return this.http.post(this.createNewClaimServiceUrl, createNewClaimModel);
  }
}
