<div class="row border box-shadow banner" 
[class.sidenav] = "screen.description !== currentScreen"
[class.banner] = "screen.description === currentScreen"
 *ngFor="let screen of claimScreens">
  <div class="col-md-12">
    <a (click)="displayScreen(screen.id, screen.description)">{{screen.description}}</a>
  </div>
</div>
<div class="row border box-shadow" 
[class.sidenav] = "'Set Status' !== currentScreen"
[class.banner] = "'Set Status' === currentScreen">
  <div class="col-md-12">
    <a (click)="displayScreen('Set Status', 'Set Status')">SET STATUS</a>
  </div>
</div>
<div class="row border box-shadow"
  [class.sidenav] = "'Send Acknowledgement' !== currentScreen"
  [class.banner] = "'Send Acknowledgement' === currentScreen">
    <div class="col-md-12">
      <a (click)="displayScreen('Send Acknowledgement', 'Send Acknowledgement')">SEND ACKNOWLEDGEMENT</a>
    </div>
</div>
<div class="row border box-shadow"
  [class.sidenav] = "'History' !== currentScreen"
  [class.banner] = "'History' === currentScreen">
    <div class="col-md-12">
      <a (click)="displayScreen('History', 'History')">HISTORY</a>
    </div>
</div>
<div class="row">
  <div class="col-md-12 sidenavbutton">
    <input type="button" value="Return" class="btn btn-colour btn-block" (click)="returnToSearchResult()" />
  </div>
  <div class="col-md-12 sidenavbutton">
    <input type="button" value="New Search" class="btn btn-colour btn-block" routerLink="/" />
  </div>
  <div class="col-md-12 sidenavbutton">
    <input type="button" value="Review" (click)="openDocForReview()" class="btn btn-colour btn-block" />
  </div>
  <div class="col-md-12 sidenavbutton">
    <input type="button" value="Send Report of Loss" (click)="sendReportOfLoss()" class="btn btn-colour btn-block" />
  </div>
</div>


