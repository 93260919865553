import { Component, Input, SimpleChanges, ChangeDetectionStrategy, Inject, SimpleChange, EventEmitter, Output, SecurityContext } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ClaimService } from '../../shared/claimservice';
import { DatePipe, DOCUMENT } from '@angular/common';
import { CommunicationService } from 'FNOLAdminWeb/app/shared/communicationservice';
import { Location } from '@angular/common';
import { TransmitService } from 'FNOLAdminWeb/app/shared/transmitservice';
import { CreateInfinityClaimService } from 'FNOLAdminWeb/app/shared/createinfinityclaimservice';
import { StateOrProvincesService } from 'FNOLAdminWeb/app/shared/stateorprovincesservice';
import { RiskCoveragesService } from 'FNOLAdminWeb/app/shared/riskcoveragesservice';
import { GridApi } from 'ag-grid-community';
import { DocumentService } from 'FNOLAdminWeb/app/shared/documentservice';
import { LossDetailService } from 'FNOLAdminWeb/app/shared/lossdetailservice';
import * as $ from 'jquery';
import { ReserveCatRendererComponent } from './gridcomponents/reservecatrenderer.component';
import { ReserveTypeRendererComponent } from './gridcomponents/reservetyperenderer.component';
import { ReserveAmountRendererComponent } from './gridcomponents/reserveamountrenderer.component';
import { ReserveProcessRendererComponent } from './gridcomponents/reserveprocessrenderer.component';
import { ReserveCoverageRendererComponent } from './gridcomponents/reservecoveragerenderer.component';
import { ReserveClaimantRendererComponent } from './gridcomponents/reserveclaimantrenderer.component';
import { ReserveDeleteRendererComponent } from './gridcomponents/reservedeleterenderer.component';
import {GridOptions} from "ag-grid-community";
import { SafeResource } from 'FNOLAdminWeb/app/pipes/safeResource.pipe';
import { SessionService } from 'FNOLAdminWeb/app/shared/sessionservice';
import { OktaAuthService } from '@okta/okta-angular';
import { ClaimPolicyService } from 'FNOLAdminWeb/app/shared/claimpolicyservice';
import { LogService } from 'FNOLAdminWeb/app/shared/logservice';
import { HistoryService } from 'FNOLAdminWeb/app/shared/historyservice';
import { SaveTransmitDetailsService } from 'FNOLAdminWeb/app/shared/savetransmitdetailsservice';
import { ClaimZipCodeService } from '../../shared/claimzipcodeservice';

@Component({
  selector: 'app-claimscreendetail-component',
  templateUrl: './claimscreendetail.component.html',
  styleUrls: ['claimscreendetail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SafeResource]
})
export class ClaimScreenDetailComponent {
  @Input() claimScreenDetail: any;
  @Input() claimId: any;
  @Input() claimStatus: any;
  @Input() claimStatusReason: any;
  @Input() subCompanyCode: any;
  @Input() openClaimsMessage: string;
  claimDetailForm: FormGroup;
  claimScreenDetailFields: any = [];
  claimScreenDataEntities: any = [];
  responseMessage: string = '';
  currentScreenId:number;
  @Output() saveScreen = new EventEmitter();
  rowData: any = [];
  reserveRowData: any = [];
  historyRowData: any = [];
  columnDefs: any = [];
  reserveColumnDefs: any = [];
  historyColumnDefs: any = [];
  canBeTransmitted:boolean = false;
  @Output() claimTransmitted = new EventEmitter();
  @Output() claimSaved = new EventEmitter();
  @Output() documentDeleted = new EventEmitter();
  @Output() lossDateUpdated = new EventEmitter();
  @Output() policyNumberUpdated = new EventEmitter();
  @Output() zipCodeUpdated = new EventEmitter();
  @Output() sentAck = new EventEmitter();
  @Output() cantBeTransmitted = new EventEmitter();
  private gridApi;
  private reserveGridApi;  
  private historyGridApi;
  claimReserves:Array<ClaimReserve> = new Array<ClaimReserve>();
  reserveGridOptions;
  historyGridOptions;
  coverageGridOptions;
  states:any[];
  fvWithChildren:FVWithChildren[];
  activeScreenDescription:string;

  constructor(private fb: FormBuilder, private domSanitizer: DomSanitizer,
    private claimService: ClaimService, @Inject(DOCUMENT) document, private safeResource: SafeResource,
    private communicationService: CommunicationService, private _location: Location,
    private transmitService: TransmitService,
    private createInfinityClaimService: CreateInfinityClaimService,
    private stateOrProvincesService:StateOrProvincesService,
    private riskCoveragesService:RiskCoveragesService,
    private datePipe:DatePipe,
    private documentService:DocumentService,
    private lossDetailService:LossDetailService,
    private claimPolicyService:ClaimPolicyService,
    private sessionService:SessionService,
    private _oktaAuthService: OktaAuthService,
    private logService: LogService,
    private historyService: HistoryService,
    private saveTransmitDetailsService: SaveTransmitDetailsService,
    private claimZipCodeService: ClaimZipCodeService) {
      this.reserveGridOptions = <GridOptions>{
        headerHeight:40,
        context: {
            componentParent: this
        }
    };
    this.historyGridOptions = <GridOptions>{
      headerHeight:40,
      context: {
          componentParent: this
      }
  };
  this.coverageGridOptions = <GridOptions>{
    headerHeight:40,
    context: {
        componentParent: this
    }
  };
  }

  ngOnInit() {
    this.states = this.claimService.getStates();
    if(this.sessionService.userId === '' || this.sessionService.userId === undefined)
      this.sessionService.userId = this._oktaAuthService.authStateManager._authState.idToken?.claims.email ?? '';
    //console.log('userOrg: ' + this.sessionService.getUserOrg());
    this.claimDetailForm = this.fb.group({
      fieldArray: this.fb.array([])
    });
  }

  get fieldArray() {
    return this.claimDetailForm.get('fieldArray') as FormArray;
  }

  visibleFields = [];

  isVisible(fieldId) : boolean{
    this.dependentFieldMaps.forEach(dfm => {
      if (dfm.fieldValueMap.dependentFields.indexOf(fieldId) !== -1) {
        let field = this.claimScreenDetailFields.filter(function (f) {
          return f.fieldId === dfm.fieldId;
        });
        let currentFieldValue = this.findControl(field[0].fieldId).value;
        if (currentFieldValue === undefined || currentFieldValue === null) {
          return true;
        }
        else {
          let fieldValue = field[0].fieldValues.filter(function (fv) {
            return fv.name === currentFieldValue;
          });
          if (fieldValue.length > 0) {
            //console.log(+fieldValue[0].id === dfm.fieldValueMap.fieldValue);
            return +fieldValue[0].id === dfm.fieldValueMap.fieldValue;
          }
          else
            return true;
        }
      }
      else {
        //console.log('fieldValueMap else return true');
        return true;
      }
    });
    return true;
  }

  buildForm(withDefaults) {
    let group = {};
    this.claimScreenDataEntities.forEach((de, i) => {
      de.fields.forEach(f => {
        if(f.isRequired){
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId,
            withDefaults ? 0 : de.dataEntityId,
            withDefaults ? '' : f.fieldValue, [Validators.required]);
        }
        else{
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId,
            withDefaults ? 0 : de.dataEntityId,
            withDefaults ? '' : f.fieldValue);
        }
      });
    });
    return this.fb.group(group);
  }

  onFileChange(event, fieldId) {
    this.readFile(fieldId, event.target.files[0]);
  }

  sanitizeImageData(readerResult){
    if(readerResult.toString().indexOf(',') !== -1){
      let commaIndex = readerResult.toString().indexOf(',')
      return readerResult.toString().substring(commaIndex+1);
    }
    return readerResult.toString();
  }

  displaySendAcknowledgement(): boolean{
    return this.claimScreenDetail.description !== undefined 
            && this.claimScreenDetail.description === 'Send Acknowledgement' 
            &&  (this.claimStatus === 'Finalized' || this.claimStatus === 'Finalized*');
  }

  private readFile(fieldId: any, theFile: any) {
    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = this.sanitizeImageData(reader.result);
      
      let field = this.claimScreenDetailFields.filter(function (f) {
        return f.fieldId === fieldId;
      });
      field[0].fieldValue = this.sanitizeImageData(reader.result);
      
      let fields: any = [];
      for (let i = 0; i < this.fieldArray.controls.length; i++) {
        let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
        for (const field in currentFormGroup.controls) {
          let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
          if (currentcontrol.pristine === false && currentcontrol.touched === true) {
            let docField = this.claimScreenDetailFields.filter(function (f) {
              return f.fieldType === 'Document' && f.fieldId === fieldId;
            });
            fields.push(
              {
                fieldid: currentcontrol.id,
                dataEntityId: currentcontrol.dataEntityId,
                fieldvalue: docField.length > 0 ? docField[0].fieldValue : currentcontrol.value,
                file: file
              });
          }
        }
      }

      if (fields.length > 0) {
        this.claimService.saveClaim(this.claimId, fields).subscribe(response => { 
          this.saveScreen.emit();
        });
      }


      //console.log('Image field: ' + JSON.stringify(field, this.getCircularReplacer()));
    }

    // Read the file
    reader.readAsDataURL(theFile);
    return file;
  }

  findControl(formControlId) {
    for (let i = 0; i < this.fieldArray.controls.length; i++) {
      let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
      for (const field in currentFormGroup.controls) {
        let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
        if (currentcontrol.id === formControlId) {
          return currentcontrol;
        }
      }
    }
  }

  isValidEmail(fieldId){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
    if(currentFormGroup.controls['field'+fieldId].errors != null && currentFormGroup.controls['field'+fieldId].errors.email)
      return true;
    return false;
  }

  isRequired(fieldId, i){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    let field = currentFormGroup.controls['field'+fieldId];
    if(field.touched && field.errors != null && field.errors?.required)
      return true;
    return false;
  }

  isMandatory(fieldId, i){
    if(this.claimScreenDetail.description === 'VERIFY TRANSMIT DETAILS'){
      let field = this.claimScreenDetailFields.find(cs => cs.fieldId === fieldId);
      return field.isRequired;
    }
    return false;
  }

  displayCharCount(fieldId, i){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    let field = currentFormGroup.controls['field'+fieldId];
    let chCount = 500 - field.value.length;
    return chCount;
  }
  
  displayCharCountForText(fieldId, i, maxAllowed){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    let field = currentFormGroup.controls['field'+fieldId];
    let chCount = maxAllowed - field.value.length;
    return chCount;
  }

  isMaxLengthExceeded(fieldId, i){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    let field = currentFormGroup.controls['field'+fieldId];   
    return field.value?.length > 500;
  }

  
  isMaxLengthExceededForText(fieldId, i, maxAllowed){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    let field = currentFormGroup.controls['field'+fieldId];   
    return field.value?.length > maxAllowed;
  }

  hasValue(fieldId, i){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    let field = currentFormGroup.controls['field'+fieldId];
    return field.value?.length > 0;
  }

  buildFormArray() {
    let groups = Array<FormGroup>();
    let group = {};
    this.claimScreenDataEntities.forEach((de, i) => {
      de.fields.forEach(f => {
        if (f.fieldType === 'Document')
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId, de.dataEntityId,
            '', [Validators.required]);
        else if (f.fieldDescription.indexOf('E-Mail') !== -1)
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId, de.dataEntityId,
                f.fieldValue, [Validators.required, Validators.email]);
        else if((f.fieldType === 'Text' || f.fieldType === 'TextArea') && f.isRequired)
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId, de.dataEntityId,
            f.fieldValue, [Validators.required]);
        else if(f.fieldType === 'Date')
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId, de.dataEntityId,
            f.fieldValue, [Validators.required, this.isValidDate]);
        else
          group['field' + f.fieldId] = new FormControlCustom(f.fieldId, de.dataEntityId,
            f.fieldValue);
      });

      groups.push(this.fb.group(group));
      group = [];
    });
    return this.fb.array(groups);
  }

  isValidDate(c: FormControl){
    const DATE_REGEXP = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    let minValid = false;
    if(c.value !== ''){
      let date = new Date(c.value);
      let minDate = new Date('2000-01-01');
      if(date > minDate)
        minValid = true;
    }
    return DATE_REGEXP.test(c.value) || c.value === '' ? null : {
      validateDate: {
        valid: false
      }
    }
  }

  deleteEntity(formGroup){
    let dataEntityId:Number = 0;
    let fieldId:Number = 0;
    for (const field in formGroup.controls) {
      let currentcontrol = formGroup.get(field) as FormControlCustom;
      if(currentcontrol.dataEntityId > 0){
        dataEntityId = currentcontrol.dataEntityId;
        fieldId = currentcontrol.id;
        break;
      }
    }
    
    this.claimService.deleteEntity(dataEntityId, fieldId).subscribe(response => { 
      this.saveScreen.emit();
    });
  }

  
  displayField(fieldId, fieldValue) {
    if(this.fvWithChildren !== undefined && this.fvWithChildren.length > 0){
        for(let fv of this.fvWithChildren){
          let parentControl = (<FormGroup>this.fieldArray.controls[0]).controls['field' + fv.fieldId];
          if(parentControl !== null && parentControl !== undefined){
            let currentVal = parentControl.value;
            if(currentVal == fv.id){            
                for(let cf of fv.childfieldsandbehaviorattributes){
                  if(cf.fieldid === fieldId){
                    if(cf.behavior === 'show')
                      return true;
                    else
                      return false;
                  }
              }
            }
          }
        }
      }
    }

  buildFormArrayWithoutDataEntities() {
    let groups = Array<FormGroup>();
    let group = {};
    this.claimScreenDetailFields.forEach((f, i) => {
      if(f.isRequired){
        group['field' + f.fieldId] = new FormControlCustom(f.fieldId, f.dataEntityId,
          f.fieldValue, [Validators.required]);
      }
      else{
        group['field' + f.fieldId] = new FormControlCustom(f.fieldId, f.dataEntityId,
          f.fieldValue);
      }
    });
    groups.push(this.fb.group(group));
    return this.fb.array(groups);
  }

  toggleDependentFields(excludeControl: FormControlCustom) {
    for (let i = 0; i < this.fieldArray.controls.length; i++) {
      let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
      for (const field in currentFormGroup.controls) {
        let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
        if (currentcontrol.id !== excludeControl.id) {
          //console.log('remove control ' + this.fieldArray.get(i + '.field' + currentcontrol.id));
          currentFormGroup.removeControl('field' + currentcontrol.id);
          }
        }
      }
    }

  dependentFieldMaps: Array<DependentFieldMap> = [];

  ngOnChanges(changes: SimpleChanges) {
    if(this.activeScreenDescription === 'VERIFY TRANSMIT DETAILS' 
        && changes.claimScreenDetail.currentValue.description !== 'VERIFY TRANSMIT DETAILS'
        &&  (this.claimStatus !== 'Finalized' && this.claimStatus !== 'Finalized*')){
      this.activeScreenDescription = changes.claimScreenDetail.currentValue.description;
      let createInfinityClaimModel = this.buildCreateInfinityModel();            
      this.logService.logMessage(JSON.stringify(createInfinityClaimModel));
      this.saveTransmitDetailsService.save(createInfinityClaimModel).subscribe((response:ServiceResponse) => {
        if(response.errorMessage !== '' && response.errorMessage !== undefined && response.errorMessage !== null)
          this.claimSaved.emit({ event:{message : '', error : response.errorMessage}, ui: null });
        else
          this.claimSaved.emit( { event:{message : 'Save was successful.', error : ''}, ui: null });
      },
      (response:any) => {
        if(response.error?.status === 400){
          this.claimSaved.emit({ event:{message : '', error : 'Invalid Request. Unable to Save.'}, ui: null });
        }
        if(response.error.errorMessage !== '' && response.error.errorMessage !== undefined && response.error.errorMessage !== null)
          this.claimSaved.emit({ event:{message : '', error : response.error.errorMessage}, ui: null });
      });
    }
    this.activeScreenDescription = changes.claimScreenDetail.currentValue.description;
    
    if (changes.claimScreenDetail !== undefined && changes.claimScreenDetail.currentValue !== undefined && changes.claimScreenDetail.currentValue.dataEntities !== undefined
      && changes.claimScreenDetail.currentValue.dataEntities[0].fields !== undefined) {
      this.selectedCoverages = [];
      this.reserveCoverages = []; 
      this.canBeTransmitted = changes.claimScreenDetail.currentValue.canBeTransmitted;
      this.currentScreenId = changes.claimScreenDetail.currentValue.id;
      this.claimScreenDetailFields = changes.claimScreenDetail.currentValue.dataEntities[0].fields;
      this.claimScreenDetailFields.forEach(f => {
        if (f.fieldType === 'Document' && f.fieldValue !== '') {
           f.fieldValue = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, this.domSanitizer.bypassSecurityTrustResourceUrl(f.fieldValue));
           //console.log('document value: ' + f.fieldValue);
        }
      });

      this.claimScreenDataEntities = changes.claimScreenDetail.currentValue.dataEntities;
      //let resultArray: Array<any> = []
      //let dataEntityId = changes.claimScreenDetail.currentValue.dataEntities[0].dataEntityId;
      //changes.claimScreenDetail.currentValue.dataEntities[0].fields.forEach(f => resultArray.push(
      //  new FormControlCustom(f.fieldId, dataEntityId, f.fieldValue, [Validators.required])
      //));
      this.claimDetailForm = this.fb.group({
        fieldArray: this.buildFormArray()
      });
      
      if (changes.claimScreenDetail.currentValue.fieldValueFields !== null) {
        let fieldValueFieldMap = changes.claimScreenDetail.currentValue.fieldValueFields[0];
        changes.claimScreenDetail.currentValue.dataEntities[0].fields.forEach(f => {
          if (f.fieldValues !== null &&
            f.fieldValues.some(fv => fv.id === fieldValueFieldMap.fieldValueId)) {

            /*let fieldValueMap = new FieldValueMap();
            fieldValueMap.fieldValue = fieldValueFieldMap.fieldValueId;
            fieldValueMap.dependentFields = fieldValueFieldMap.fieldIds.slice();
            let dependentFieldMap = new DependentFieldMap();
            dependentFieldMap.fieldId = f.fieldId;
            dependentFieldMap.fieldValueMap = fieldValueMap;
            this.dependentFieldMaps.push(dependentFieldMap);
            */
            let fieldValue = f.fieldValues.filter(function (fv) {
              return fv.id == fieldValueFieldMap.fieldValueId;
            });
            for (let i = 0; i < this.fieldArray.controls.length; i++) {
              let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
              for (const field in currentFormGroup.controls) {
                let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
                if (currentcontrol.id === f.fieldId) {
                  if (+currentcontrol.value === fieldValue[0].id) {
                    this.visibleFields = fieldValueFieldMap.fieldIds.slice();
                  }
                  currentcontrol.valueChanges.subscribe(value => {
                    if (+value === fieldValue[0].id) {
                      this.visibleFields = fieldValueFieldMap.fieldIds.slice();
                    }
                    else
                      this.visibleFields = [];
                  });
                }
              }
            }
          }
          else{
            if(this.visibleFields?.length > 0)
              this.visibleFields.push(f.fieldId);
            else{
              this.visibleFields = []            
              this.visibleFields.push(f.fieldId);
            }
          }
        });
      }
      else {
        let allFieldIds = []
        this.claimScreenDetailFields.forEach(function (f) { allFieldIds.push(f.fieldId) });
        this.visibleFields = allFieldIds.slice();
      }

      if(changes.claimScreenDetail.currentValue.description === 'TRANSMIT'){
        let policyRefField = this.claimScreenDetailFields.filter(function(f){
          return f.fieldDescription === 'Policy Number:'; 
        });
        let lossDateField = this.claimScreenDetailFields.filter(function(f){
          return f.fieldDescription === 'Loss Date:'; 
        });
        let subCompanyCodeField = this.claimScreenDetailFields.filter(function(f){
          return f.fieldDescription === 'SubCompanyCode'; 
        });

        let companyDetails = {
          SubCompanyCode: subCompanyCodeField[0].fieldValue,
          PolicyRef: policyRefField[0].fieldValue,
          LossDate: lossDateField[0].fieldValue
      }
        this.transmitService.getTransmitInfo(companyDetails);
      }

      let gridField = this.claimScreenDetailFields.filter(function(f){
          return f.fieldType === 'Grid';
      });
      
      if(gridField[0] !== undefined){
        this.selectedCoverages = gridField[0].selectedCoverages;
      }

      this.columnDefs = [
        { headerName: '', field: 'claimId', checkboxSelection: true, width:10 },
        { headerName: 'Coverage Description', field: 'description', width:300 },
        { headerName: 'PerOccurLimit', field: 'perOccurLimit', cellRenderer: this.currencyCellRendererUSD },
        { headerName: 'AggregateLimit', field: 'aggregateLimit', cellRenderer: this.currencyCellRendererUSD },
        { headerName: 'PerOccurDed', field: 'perOccurDed', cellRenderer: this.currencyCellRendererUSD  },
        { headerName: 'Add Date', field: 'addDate', valueFormatter: this.dateFormatter },
        { headerName: 'From Date', field: 'fromDate', valueFormatter: this.dateFormatter }
      ];
      if(this.rowData.length == 0 && gridField[0] !== undefined){        
        gridField[0].fieldValues.forEach(fv => {
          let nameParts = fv.name.split(',');
          this.rowData.push({
            id : fv.id,
            description: nameParts[0],
            perOccurLimit: nameParts[1],
            aggregateLimit: nameParts[2],
            perOccurDed: nameParts[3],
            addDate: nameParts[4],
            fromDate: nameParts[5],
            covTypeId: nameParts[6],
            uwRiskId: nameParts[7]
          });
        });
    }

    let reserveGridField = this.claimScreenDetailFields.filter(function(f){
      return f.fieldType === 'ReserveGrid';
  });
  
  let claimantColumnDef = { headerName: 'Claimant', field: 'reserveClaimants', 
  cellRendererFramework: ReserveClaimantRendererComponent, hide:true };
  if(this.subCompanyCode === 'UAV' || this.subCompanyCode === 'AAV' || this.subCompanyCode === ''){
    claimantColumnDef = { headerName: 'Claimant', field: 'reserveClaimants', 
    cellRendererFramework: ReserveClaimantRendererComponent, hide: false };
  }
    this.reserveColumnDefs = [
      { headerName: '', field: '', 
      cellRendererFramework: ReserveDeleteRendererComponent},
      claimantColumnDef,
      { headerName: 'Reserve Category', field: 'reserveCat',
      cellRendererFramework: ReserveCatRendererComponent},
      { headerName: 'Coverage', field: 'reserveCoverages',
      cellRendererFramework: ReserveCoverageRendererComponent },
      { headerName: 'Reserve Type', field: 'reserveType',
      cellRendererFramework: ReserveTypeRendererComponent },
      { headerName: 'Amount', field: 'amount',
      cellRendererFramework: ReserveAmountRendererComponent },
      { headerName: 'Process', field: 'process',
      cellRendererFramework: ReserveProcessRendererComponent }
    ];

    if(reserveGridField[0] !== undefined && reserveGridField[0].reserveClaimants !== null){    
            reserveGridField[0].reserveClaimants.forEach(rc => {
              this.reserveClaimants.push({
                id:rc.contactId,
                desc:rc.desc,
                type:rc.type
              });
            }); 
            
            if(reserveGridField[0].transmitDetailReserves.length > 0){
              this.reserveRowData = [];
              reserveGridField[0].transmitDetailReserves.forEach(tr => {
                this.reserveRowData.push({
                  reserveCat : reserveGridField[0].reserveCategories,
                  reserveType: reserveGridField[0].reserveTypes,
                  reserveCoverages: this.reserveCoverages,
                  reserveClaimants: this.reserveClaimants,
                  amount: tr.reserveAmount,
                  process: tr.processReserve,
                  reserveCategory: tr.reserveCatId,
                  coverageTypeId: tr.coverageTypeId,
                  reserveTypeId: tr.reserveTypeId,
                  uwRiskId: tr.uwRiskId
                });
            });
          }
            else{        
              if(this.reserveRowData.length === 0){
                this.reserveRowData.push({
                  reserveCat : reserveGridField[0].reserveCategories,
                  reserveType: reserveGridField[0].reserveTypes,
                  reserveCoverages: this.reserveCoverages,
                  reserveClaimants: this.reserveClaimants,
                  amount: 0,
                  process: false
                });
              }
            }
    }
      
      if (changes.claimScreenDetail.currentValue !== undefined && 
        changes.claimScreenDetail.currentValue.description === 'VERIFY TRANSMIT DETAILS') {
        this.setDefaultCountryToUSA();
      }

      if(this.claimScreenDataEntities[0].fields.some(f => f.fieldType.toLowerCase() === 'dropdown' && f.fieldValues !== undefined && f.fieldValues.length > 0)){
        let fieldsWithFieldValues = this.claimScreenDataEntities[0].fields.filter(f => f.fieldValues !== undefined && f.fieldValues !== null && f.fieldValues.length > 0);
        fieldsWithFieldValues.forEach(fwfv => {
            if(fwfv.fieldValues.some(fv => fv.childfieldsandbehaviorattributes !== undefined && fv.childfieldsandbehaviorattributes !== null && fv.childfieldsandbehaviorattributes.length > 0)){
              let fieldValuesWithChildren = fwfv.fieldValues.filter(fv => fv.childfieldsandbehaviorattributes !== undefined && fv.childfieldsandbehaviorattributes !== null && fv.childfieldsandbehaviorattributes.length > 0);
              this.fvWithChildren = fieldValuesWithChildren;
            }
        });
      }
}
    else if (changes.claimScreenDetail.currentValue !== undefined && changes.claimScreenDetail.currentValue.description === 'Set Status') {
      let claimStatusId = "";
      //console.log('claim status: ' + this.claimStatus);
      switch(this.claimStatus){
        case 'Pending':
          claimStatusId = "5";
          break;
        case 'Verified':
          claimStatusId = "6";
          break;
        case 'Approved':
          claimStatusId = "7";
          break;
        case 'Rejected':
          claimStatusId = "8";
          break;
        case 'Finalized':
          claimStatusId = "9";
          break;
        default:
          break;
      }
      let fieldArr = [
        {
          "fieldId": 1,
          "fieldOrder": 1,
          "fieldType": "Radio",
          "fieldValue": claimStatusId,
          "fieldValues":[
            {"id":5,"name":"Pending"},
            {"id":6,"name":"Verified"},
            {"id":7,"name":"Approved"},
            {"id":8,"name":"Rejected"}
          ],
          "fieldDescription": "",
        },
        {
          "fieldId": 2,
          "fieldOrder": 3,
          "fieldType": "ReasonText",
          "fieldValue": this.claimStatusReason,
          "fieldValues":null,
          "fieldDescription": "Reason: ",
        }
      ];
      this.claimScreenDetailFields = fieldArr;
      this.claimDetailForm = this.fb.group({
        fieldArray: this.buildFormArrayWithoutDataEntities()
      });
    }
    else if (changes.claimScreenDetail.currentValue !== undefined && changes.claimScreenDetail.currentValue.description === 'Send Acknowledgement') {
      this.communicationService.getAcknowledgementBody(this.claimId).subscribe((result:any) => { 
        let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
        currentFormGroup.controls['field5'].setValue(result.result.body);
        currentFormGroup.controls['field1'].setValue(result.result.to);
        currentFormGroup.controls['field4'].setValue(result.result.subject);
        //$('textarea').val(result.result);
     }); 
      
      let fieldArr = [
        {
          "fieldId": 1,
          "fieldOrder": 1,
          "fieldType": "Text",
          "fieldValue": "",
          "fieldDescription": "To:"
        },
        {
          "fieldId": 2,
          "fieldOrder": 2,
          "fieldType": "Text",
          "fieldValue": "",
          "fieldDescription": "CC:",
          "isRequired": false
        },
        {
          "fieldId": 3,
          "fieldOrder": 3,
          "fieldType": "Text",
          "fieldValue": "",
          "fieldDescription": "BCC:",
          "isRequired": false
        },
        {
          "fieldId": 4,
          "fieldOrder": 4,
          "fieldType": "TextReadOnly",
          "fieldValue": "",
          "fieldDescription": "Subject:"
        },
        {
          "fieldId": 5,
          "fieldOrder": 5,
          "fieldType": "SATextArea",
          "fieldValue": '',
          "fieldDescription": "Body:"
        }
      ];
      this.visibleFields.push(1, 2, 3, 4, 5);
      this.claimScreenDetailFields = fieldArr;
      this.claimDetailForm = this.fb.group({
        fieldArray: this.buildFormArrayWithoutDataEntities()
      });
    }
    else if(changes.claimScreenDetail.currentValue !== undefined && changes.claimScreenDetail.currentValue.description === 'History'){
        let fieldArr = [
          {
            "fieldId": 1,
            "fieldOrder": 1,
            "fieldType": "HistoryGrid",
            "fieldValue": "",
            "fieldDescription": "",
            "isRequired": false
          }
        ];
        this.claimScreenDetailFields = fieldArr;
        this.claimDetailForm = this.fb.group({
          fieldArray: this.buildFormArrayWithoutDataEntities()
        });
        this.historyColumnDefs = [
          { headerName: 'Timestamp', field: 'timestamp', valueFormatter: this.dateFormatter, width:50},
          { headerName: 'Event', field: 'message', 
          cellStyle: (params) => {
            console.log(params.data.messageType);
            if (params.data.messageType === 'Error') {
              return { 'color': 'red', 'white-space': 'normal' };
            } else {
              return { 'white-space': 'normal' };
            }
          }, autoHeight: true}
        ];
          this.historyService.getHistory(this.claimId).subscribe((result:any) => { 
            this.historyRowData = []
            for(let item of result.result){
              this.historyRowData.push({timestamp: item.timestamp, message: item.message, messageType: item.messageType});
            }
            this.historyGridApi.setRowData(this.historyRowData);
        }); 
      }
  }

  disabledAfterTransmit(){
    return (this.claimScreenDetail.description !== 'Send Acknowledgement' && (this.claimStatus === 'Finalized' || this.claimStatus === 'Finalized*')) ? true : null;
  }

  displaySave(){
    return this.claimScreenDetail.description !== undefined 
            && this.claimScreenDetail.description !== 'Send Acknowledgement' 
            &&  !(this.claimStatus === 'Finalized' || this.claimStatus === 'Finalized*');
  }

  currencyCellRendererUSD(params: any) {
    var inrFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return inrFormat.format(params.value);
  }

  dateFormatter(params: any) {
    return params.value ? (new Date(params.value)).toLocaleDateString() : '';
  }
  
  reserveClaimants:Array<ReserveClaimant> = [];
  onNameTypeChange(event){     
    let id = event.target.id;
    let idParts = id.split('-');
    let controlId = +idParts[idParts.length-1];
    let nameTypeField = this.claimScreenDetailFields.filter(function(f){
      return f.fieldId === controlId;
    });
    if(nameTypeField[0] !== undefined){
      let value = +event.target.value;
      let fieldValue = nameTypeField[0].fieldValues.filter(function(fv){
        return fv.id === value;
      });
      if(fieldValue[0] !== undefined && fieldValue[0].name === 'CL - Claimant'){
        let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
        let nameTypeId = currentFormGroup.controls['field' + controlId].value;
        let contactLabel = $('label[for*="' + controlId + '"]');
        let contactData = contactLabel.attr('data-contactdata');
        let contactDataParts = contactData.split('-');
        let contactId = contactDataParts[1].trim();
        let contactType = contactDataParts[0].trim();
        
        this.reserveClaimants.push({
          id:contactId,
          desc:contactLabel[0].innerText.substring('Name Type for '.length),
          type:contactType
        });
        this.reserveGridApi.refreshCells({ force: true, columns:['Claimant'] });
      }
    }
  }

  setDefaultCountryToUSA(){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
    currentFormGroup.controls['field108'].setValue('USA-UNITED STATES');
  }

  onCountryChange(selectedValue){    
    let selectedValueParts = selectedValue.split('-');
    let countryCode = selectedValueParts[0];
   this.stateOrProvincesService.get(countryCode).subscribe((result:ServiceResponse) => {    
      let stateField = this.claimScreenDetailFields.filter(function(f){
        return f.fieldDescription === 'Loss State:'
      });
      stateField[0].fieldValues = result.result;
   }); 
  }

  onRiskChange(selectedValue){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
    let policyTxnFieldId = $('.PolicyTxnId').attr('id');
    let policyTxnFieldIdParts = policyTxnFieldId.split('-');
    let policyTxnFieldName = 'field' + policyTxnFieldIdParts[policyTxnFieldIdParts.length-1];
    let policyTxnId = currentFormGroup.controls[policyTxnFieldName].value;
    let lossDate = this.datePipe.transform(currentFormGroup.controls['field101'].value, 'yyyy-MM-dd');
    
    this.riskCoveragesService.get(policyTxnId, lossDate, selectedValue)
          .subscribe((result:ServiceResponse) => {
      this.rowData = [];
      let gridField = this.claimScreenDetailFields.filter(function(f){
        return f.fieldType === 'Grid';
      });
      gridField[0].fieldValues = result.result;
      gridField[0].fieldValues.forEach(fv => {
        let nameParts = fv.name.split(',');
        this.rowData.push({
          id : fv.id,
          description: nameParts[0],
          perOccurLimit: nameParts[1],
          aggregateLimit: nameParts[2],
          perOccurDed: nameParts[3],
          addDate: nameParts[4],
          fromDate: nameParts[5],
          covTypeId: nameParts[6],
          uwRiskId: nameParts[7]
        });
      });
      this.gridApi.setRowData(this.rowData);
   });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //params.api.sizeColumnsToFit();
  }

  onReserveGridReady(params) {
    this.reserveGridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  onHistoryGridReady(params) {
    this.historyGridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  addForm() {
    this.fieldArray.push(this.buildForm(true));
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  selectedCoverages:any = [];
  reserveCoverages:Array<ReserveCoverage> = [];
  onRowSelected(event: any) { 
    if(event.node.selected) {
      this.selectedCoverages.push(event.data.id.toString());
      this.reserveCoverages.push({id: event.node.data.covTypeId.toString() + '-' + event.node.data.uwRiskId, description: event.data.description});
    }
    else{
      this.selectedCoverages = this.selectedCoverages.filter(function(c){
          return c !== event.data.id.toString();
      });
      this.reserveCoverages = this.reserveCoverages.filter(function(c){
        return c.id !== event.node.data.covTypeId.toString() + '-' + event.node.data.uwRiskId;
    });
    }
    
    this.reserveGridApi.refreshCells({ force: true, columns:['reserveCoverages'] });
   //console.log("selection", event.data); 
  }

  onRowDataChanged(): void {
    //this.coverageGridOptions.api.forEachNode(node => this.selectedCoverages.indexOf(node.data.id.toString()) !== -1 ? console.log(node) : 0);
    this.coverageGridOptions.api.forEachNode(node => this.selectedCoverages.indexOf(node.data.id.toString()) !== -1 ? node.setSelected(true) : node.setSelected(false));
  }

  addReserve(event) {
    let reserveGridField = this.claimScreenDetailFields.filter(function(f){
      return f.fieldType === 'ReserveGrid';
    });
    this.reserveGridApi.applyTransaction({
      add: [{
        reserveCat : reserveGridField[0].reserveCategories,
        reserveType: reserveGridField[0].reserveTypes,
        reserveCoverages: this.reserveCoverages,
        reserveClaimants: this.reserveClaimants,
        amount: 0,
        process: false
      }]
    });
  }

  onReserveRowSelected(event: any) { }

  checkWordFileType(field){
    let retVal = false;

    if(field.fieldValue !== '' && (field.fieldValue.indexOf('data:image') === -1 && field.fieldValue.indexOf('data:application/pdf') === -1))
    {
      if(field.additionalData != null && field.additionalData.indexOf('doc') !== -1)
        retVal = true;
    }

    return retVal;
  }

  checkExcelFileType(field){
    let retVal = false;

    if(field.fieldValue !== '' && (field.fieldValue.indexOf('data:image') === -1 && field.fieldValue.indexOf('data:application/pdf') === -1))
    {
      if(field.additionalData != null && field.additionalData.indexOf('xls') !== -1)
        retVal = true;
    }

    return retVal;
  }

  buildCreateInfinityModel(){
    let createInfinityClaimModel = {};
    let contacts = [];
    let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
    let id = 126;
    if(this.subCompanyCode === 'UAV' || this.subCompanyCode === 'AAV' || this.subCompanyCode === '')
      id = 117;
    else if(this.subCompanyCode === 'ARC' || this.subCompanyCode === 'AXL' || this.subCompanyCode === 'PRD')
      id = 125;
    while($('label[for*="' + id + '"]').length > 0){
      let nameTypeId = currentFormGroup.controls['field' + id].value;
      let contactLabel = $('label[for*="' + id + '"]');
      let contactData = contactLabel.attr('data-contactdata');
      if(contactData !== undefined){
        let contactDataParts = contactData.split('-');
        let contactId = contactDataParts[1].trim();
        let contactType = contactDataParts[0].trim();
        if(nameTypeId != null && nameTypeId.length > 0){
          let nameType = '';
          let nameTypeDDL = $('select[id$="' + id + '"]')[0];
          for (let index = 0; index < nameTypeDDL.options.length; index++) {
            if(nameTypeDDL.options[index].value == nameTypeId){
              nameType = nameTypeDDL.options[index].text;
            }
          }
          contacts.push(
            {
              contactId : contactId,
              contactType : contactType,
              nameTypeIds : [nameTypeId],
              nameType: nameType            
            });
        }
        id++;
      }
      else
        break;
    }
    if(this.subCompanyCode === 'UAV' || this.subCompanyCode === 'AAV' || this.subCompanyCode === ''){
      createInfinityClaimModel = {
        'fnolClaimId' : this.claimId,
        'lossDate' : currentFormGroup.controls['field101'].value,
        'claimsMadeDate' : currentFormGroup.controls['field102'].value,
        'reportedDate' : currentFormGroup.controls['field103'].value,
        'reportedBy' : this.getIntFieldValue('field104'),
        'lossCountryCode' : currentFormGroup.controls['field108'].value.split('-')[0],
        'uWCoverageIds' : this.selectedCoverages,
        'lossStateId' : currentFormGroup.controls['field109'].value === '' ? 0 : currentFormGroup.controls['field109'].value,
        'lossCity' : currentFormGroup.controls['field110'].value,
        'lossDescription' : currentFormGroup.controls['field111'].value,
        'claimsAttorney' : this.getIntFieldValue('field112'),
        'internalAdjuster' : this.getIntFieldValue('field113'),
        'severityId' : this.getIntFieldValue('field114'),
        'causeOfLossId' : this.getIntFieldValue('field115'),
        'secondaryCauseOfLossId' : this.getIntFieldValue('field116'),
        'contacts': contacts,
        'subCompanyCode' : $('.SubCompanyCode').val(),
        'policyTxnId' : $('.PolicyTxnId').val(),
        'caseTypeId' : $('.CaseTypeId').val(),
        'reserves' : this.claimReserves,
        'userId' : this.sessionService.userId,
        'lossStateCode':this.getStateCode(currentFormGroup.controls['field109'].value),
        'riskId': this.getIntFieldValue('field106'),
        'internalAdjusterName': $('#field-0-113 option:selected').text().trim()
      };
    }
    else{
      if(this.subCompanyCode === 'ARC' || this.subCompanyCode === 'AXL' || this.subCompanyCode === 'PRD'){
        createInfinityClaimModel = {
          'fnolClaimId' : this.claimId,
          'lossDate' : currentFormGroup.controls['field103'].value,
          'claimsMadeDate' : currentFormGroup.controls['field104'].value,
          'reportedDate' : currentFormGroup.controls['field105'].value,
          'reportedTypeId' : currentFormGroup.controls['field106'].value,
          'reportedBy' : currentFormGroup.controls['field107'].value,
          'lossCountryCode' : currentFormGroup.controls['field110'].value.split('-')[0],
          'uWCoverageIds' : this.selectedCoverages,
          'lossStateId' : currentFormGroup.controls['field111'].value === '' ? 0 : currentFormGroup.controls['field111'].value,
          'lossCity' : currentFormGroup.controls['field112'].value,
          'insuredType' : currentFormGroup.controls['field113'].value !== '' ? currentFormGroup.controls['field113'].value : 0,
          'coverageType' : currentFormGroup.controls['field114'].value !== '' ? currentFormGroup.controls['field114'].value : 0,
          'lossCause' : currentFormGroup.controls['field115'].value !== '' ? currentFormGroup.controls['field115'].value : 0,   
          'damageType' : currentFormGroup.controls['field116'].value !== '' ? currentFormGroup.controls['field116'].value : 0,          
          'lossDescription' : currentFormGroup.controls['field117'].value,
          'adjuster' : currentFormGroup.controls['field118'].value,
          'severityId' : 0,
          'causeOfLossId' : currentFormGroup.controls['field120'].value,
          'lineOfCoverageId' : currentFormGroup.controls['field121'].value !== '' ? currentFormGroup.controls['field121'].value : 0,
          'siteCode' : currentFormGroup.controls['field122'].value,
          'catCode' : currentFormGroup.controls['field123'].value,
          'dedStatus' : currentFormGroup.controls['field124'].value,
          'secondaryCauseOfLossId' : 0,
          'contacts': contacts,
          'subCompanyCode' : $('.SubCompanyCode').val(),
          'policyTxnId' : $('.PolicyTxnId').val(),
          'reserves' : this.claimReserves,
          'userId' : this.sessionService.userId,
          'lossStateCode':this.getStateCode(currentFormGroup.controls['field111'].value),
          'internalAdjusterName': $('#field-0-118 option:selected').text().trim()
        };
      }
      else{
        createInfinityClaimModel = {
          'fnolClaimId' : this.claimId,
          'lossDate' : currentFormGroup.controls['field103'].value,
          'claimsMadeDate' : currentFormGroup.controls['field104'].value,
          'reportedDate' : currentFormGroup.controls['field105'].value,
          'reportedTypeId' : this.getIntFieldValue('field106'),
          'reportedBy' : this.getIntFieldValue('field107'),
          'lossCountryCode' : currentFormGroup.controls['field110'].value?.split('-')[0],
          'uWCoverageIds' : this.selectedCoverages,
          'lossStateId' : this.getIntFieldValue('field111'),
          'lossCity' : currentFormGroup.controls['field112'].value,
          'insuredType' : this.getIntFieldValue('field113'),
          'coverageType' : this.getIntFieldValue('field114'),
          'lossCause' : this.getIntFieldValue('field115'),   
          'damageType' : this.getIntFieldValue('field116'),          
          'lossDescription' : currentFormGroup.controls['field117'].value,
          'adjuster' : this.getIntFieldValue('field118'),
          'severityId' : 0,
          'managerId' : this.getIntFieldValue('field119'),
          'causeOfLossId' : this.getIntFieldValue('field120'),
          'caseTypeId' : this.getIntFieldValue('field121'),
          'lineOfCoverageId' : this.getIntFieldValue('field122'),
          'siteCode' : currentFormGroup.controls['field123']?.value,
          'catCode' : currentFormGroup.controls['field124']?.value,
          'dedStatus' : this.getIntFieldValue('field125'),
          'secondaryCauseOfLossId' : 0,
          'contacts': contacts,
          'subCompanyCode' : $('.SubCompanyCode').val(),
          'policyTxnId' : $('.PolicyTxnId').val(),
          'reserves' : this.claimReserves,
          'userId' : this.sessionService.userId,
          'lossStateCode' : this.getStateCode(currentFormGroup.controls['field111'].value),
          'internalAdjusterName': $('#field-0-118 option:selected').text().trim()
        };
      }
    }
    return createInfinityClaimModel;
  }

  getStateCode(state){
    let stateCode = '';
    if(state !== '' && state !== null && state !== undefined){      
      let stateField = this.claimScreenDetailFields.filter(function(f){
        return f.fieldDescription === 'Loss State:'; 
      });
      let selectedFieldValue = stateField[0].fieldValues.filter(function(fv){
        return fv.id === +state;
      });
      let stateDesc = selectedFieldValue[0].name;
      if(stateDesc !== '' && stateDesc !== null){
        let stateParts = stateDesc.split('-');
        stateCode = stateParts[0];
      }
    }
    return stateCode;
  }

  getIntFieldValue(fieldId){
    let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
    return currentFormGroup.controls[fieldId]?.value !== '' && currentFormGroup.controls[fieldId]?.value !== null 
           ? currentFormGroup.controls[fieldId]?.value : 0;
  }

  onSubmit(buttonIdEvent : MouseEvent) {
    //console.log('Submit ' + JSON.stringify(this.claimDetailForm.value));
      if(this.claimScreenDetail.description === 'Send Acknowledgement'){
      let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
      let ack: any = {
        'To' : currentFormGroup.controls['field1'].value,
        'CC' : currentFormGroup.controls['field2'].value,
        'BCC' : currentFormGroup.controls['field3'].value,
        'Subject' : currentFormGroup.controls['field4'].value,
        'Body' : currentFormGroup.controls['field5'].value,
        'ClaimId' : this.claimId
      };      
    
      this.communicationService.sendAcknowledgement(ack).subscribe((response:ServiceResponse) => {
        if(response.errorMessage !== '' && response.errorMessage !== undefined && response.errorMessage !== null)
          this.sentAck.emit({ event:{message : '', error : response.errorMessage}, ui: null });
        else
          this.sentAck.emit( { event:{message : 'Acknowledgement was sent.', error : ''}, ui: null });
      });;
      currentFormGroup.reset();
      this.responseMessage = 'Sent Acknowledgement.';
    }
    else if(this.claimScreenDetail.description === 'VERIFY TRANSMIT DETAILS'){
      if(buttonIdEvent.toString() == 'btnSave'){
        //build createInfinityModel to save transmit details
        let createInfinityClaimModel = this.buildCreateInfinityModel();            
        this.logService.logMessage(JSON.stringify(createInfinityClaimModel));
        this.saveTransmitDetailsService.save(createInfinityClaimModel).subscribe((response:ServiceResponse) => {
          if(response.errorMessage !== '' && response.errorMessage !== undefined && response.errorMessage !== null)
            this.claimSaved.emit({ event:{message : '', error : response.errorMessage}, ui: null });
          else
            this.claimSaved.emit( { event:{message : 'Save was successful.', error : ''}, ui: null });
        },
        (response:any) => {
          if(response.error?.status === 400){
            this.claimTransmitted.emit({ event:{message : '', error : 'Invalid Request. Unable to Save.'}, ui: null });
          }
          if(response.error.errorMessage !== '' && response.error.errorMessage !== undefined && response.error.errorMessage !== null)
            this.claimTransmitted.emit({ event:{message : '', error : response.error.errorMessage}, ui: null });
        });
      } 
      else{
        if(confirm('Are you sure you want to Transmit?')){
          let invalidFields = true;
          if(!this.canBeTransmitted){  
            this.cantBeTransmitted.emit({ event:{message : 'Please check your Policy Number or Date of Loss before transmitting the claim.', error : ''}, ui: null });
          }
          else if(this.selectedCoverages.length === 0){
            this.cantBeTransmitted.emit({ event:{message : 'Please select atleast one coverage before transmitting the claim.', error : ''}, ui: null });        
          }
          else{
          let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
          if(this.subCompanyCode === 'ARC' || this.subCompanyCode === 'AXL' || this.subCompanyCode === 'PRD'){
              if(currentFormGroup.controls['field102'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please enter Policy Number before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field103'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please enter Loss Date before transmitting the claim.', error : ''}, ui: null });          
              else if(currentFormGroup.controls['field104'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please enter Claims Made Date before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field105'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please enter Reported Date before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field106'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please select Reported Type before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field107'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please select Reported By before transmitting the claim.', error : ''}, ui: null });            
              else if(currentFormGroup.controls['field110'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please select Loss Country before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field111'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please select Loss State before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field117'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please enter Loss Description before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field118'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please select Adjuster before transmitting the claim.', error : ''}, ui: null });
              else if(currentFormGroup.controls['field120'].value === '')
                this.cantBeTransmitted.emit({ event:{message : 'Please select Primary Cause of Loss before transmitting the claim.', error : ''}, ui: null });
              else
                invalidFields = false;
              }
          else if(this.subCompanyCode !== '' && this.subCompanyCode !== 'UAV' && this.subCompanyCode !== 'AAV'){
            if(currentFormGroup.controls['field102'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please enter Policy Number before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field103'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please enter Loss Date before transmitting the claim.', error : ''}, ui: null });          
            else if(currentFormGroup.controls['field104'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please enter Claims Made Date before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field105'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please enter Reported Date before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field106'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please select Reported Type before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field107'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please select Reported By before transmitting the claim.', error : ''}, ui: null });            
            else if(currentFormGroup.controls['field110'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please select Loss Country before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field111'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please select Loss State before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field117'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please enter Loss Description before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field118'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please select Adjuster before transmitting the claim.', error : ''}, ui: null });
            else if(currentFormGroup.controls['field120'].value === '')
              this.cantBeTransmitted.emit({ event:{message : 'Please select Primary Cause of Loss before transmitting the claim.', error : ''}, ui: null });
            else
              invalidFields = false;
            }
            else if(this.subCompanyCode === 'UAV' || this.subCompanyCode === 'AAV'){
              invalidFields = false;
            }
        }
        if(!invalidFields){    
          let createInfinityClaimModel = this.buildCreateInfinityModel();            
          this.logService.logMessage(JSON.stringify(createInfinityClaimModel));
          this.createInfinityClaimService.create(createInfinityClaimModel).subscribe((response:ServiceResponse) => {
            if(response.errorMessage !== '' && response.errorMessage !== undefined && response.errorMessage !== null)
              this.claimTransmitted.emit({ event:{message : '', error : response.errorMessage}, ui: null });
            else
              this.claimTransmitted.emit( { event:{message : 'Transmit was successful.', error : ''}, ui: null });
          },
          (response:any) => {
            if(response.error.errorMessage !== '' && response.error.errorMessage !== undefined && response.error.errorMessage !== null)
              this.claimTransmitted.emit({ event:{message : '', error : response.error.errorMessage}, ui: null });
          });
        }        
       }
      }  
    }
    else if(this.claimScreenDetail.description === 'Set Status'){
      let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
      let status = {
                    'statusId' : currentFormGroup.controls['field1'].value, 
                    'header': {'statusReason':  currentFormGroup.controls['field2'].value}
                   };
      this.claimService.saveStatus(this.claimId, status).subscribe(response => { 
        this.saveScreen.emit();
      });
    }
    else if(this.claimScreenDetail.description === 'POLICY ZIP CODE UPDATE'){
      let currentFormGroup = <FormGroup>this.fieldArray.controls[0];
      let claimPolicyUpdateModel = {
                    'claimId' : this.claimId,
                    'policyNumber' : currentFormGroup.controls['field200'].value, 
                    'zipCode': currentFormGroup.controls['field201'].value
                   };
      this.claimPolicyService.put(claimPolicyUpdateModel).subscribe((result) => {
        this.policyNumberUpdated.emit({ event:{message : 'Policy/Zipcode updated.', error : ''}, ui: null });
        }, (error) => {
        if(error?.error?.errorMessage !== '')
          this.policyNumberUpdated.emit({ event:{message : '', error : error?.error?.errorMessage}, ui: null });
        });

    }
    else{
      let fields: any = [];
      for (let i = 0; i < this.fieldArray.controls.length; i++) {
        let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
        for (const field in currentFormGroup.controls) {
          let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
          if ((this.claimScreenDetail.description === 'PASSENGER DETAILS' || 
          this.claimScreenDetail.description === 'WITNESS DETAILS' || 
          this.claimScreenDetail.description === 'OTHER INJURIES OR THIRD-PARTY PROPERTY DAMAGE DETAILS' || 
          this.claimScreenDetail.description === 'CLAIMANT INFORMATION')
           ||
          (currentcontrol.pristine === false && currentcontrol.touched === true)) {
            let docField = this.claimScreenDetailFields.filter(function (f) {
              return f.fieldType === 'Document' && f.fieldId === currentcontrol.id;
            });
            let radioField = this.claimScreenDetailFields.filter(function (f) {
              return f.fieldType === 'Radio' && f.fieldId === currentcontrol.id;
            });
            if(radioField.length > 0 ){
              fields.push(
                {
                  fieldid: currentcontrol.id,
                  dataEntityId: currentcontrol.dataEntityId,
                  fieldvalue: currentcontrol.value
                }); 
            }else{              
            fields.push(
              {
                fieldid: currentcontrol.id,
                dataEntityId: currentcontrol.dataEntityId,
                fieldvalue: docField.length > 0 ? docField[0].fieldValue : currentcontrol.value
              }); 
            }
          }
        }
      }

      if (fields.length > 0) {
        this.claimService.saveClaim(this.claimId, fields).subscribe(response => { 
          this.saveScreen.emit();
        });
      }
    }
  }

  onDateChange(fieldDesc, screenDesc, fieldId){
    if(screenDesc === 'VERIFY TRANSMIT DETAILS' && fieldDesc === 'Loss Date:'){
    for (let i = 0; i < this.fieldArray.controls.length; i++) {
      let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
      for (const field in currentFormGroup.controls) {
        let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
        if(currentcontrol.id === fieldId){
          this.lossDetailService.put(this.claimId, currentcontrol.value).subscribe(() => {
            this.lossDateUpdated.emit();
          });
        }
      }
    }
  }
}

onZipCodeChange(fieldId){
  for (let i = 0; i < this.fieldArray.controls.length; i++) {
    let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
    for (const field in currentFormGroup.controls) {
      let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
      if(currentcontrol.id === fieldId){
        this.claimZipCodeService.put(this.claimId, currentcontrol.value).subscribe((result) => {
          this.zipCodeUpdated.emit();
        }, (error) => {
          if(error?.error?.errorMessage !== '')
            this.zipCodeUpdated.emit({ event:{message : '', error : error?.error?.errorMessage}, ui: null });
        });
      }
    }
  }
}

  deleteDocument(event, fieldId){
    for (let i = 0; i < this.fieldArray.controls.length; i++) {
      let currentFormGroup = <FormGroup>this.fieldArray.controls[i];
      for (const field in currentFormGroup.controls) {
        let currentcontrol = currentFormGroup.get(field) as FormControlCustom;
        if(currentcontrol.id === fieldId){
          this.documentService.delete(currentcontrol.dataEntityId, fieldId).subscribe(() => {
            this.documentDeleted.emit();
          });
        }
      }
    }
  }
  
  openpdf(event, fieldValue){
    let pdfValue = fieldValue.replace('data:application/pdf;base64, ', '')
    var byteCharacters = atob(pdfValue);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
  
  openImage(event, fieldValue){
    let newTab = window.open();
    newTab.document.body.innerHTML = '<img src="' + fieldValue + '">';
  }
}

export class FormControlCustom extends FormControl {

  public id: Number = 0;
  public dataEntityId: Number = 0;

  constructor(id, dataEntityId, ...args) {
    super(...args);
    this.id = id;
    this.dataEntityId = dataEntityId;
  }

}

class FileToUpload {
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  lastModifiedTime: number = 0;
  lastModifiedDate: Date = null;
  fileAsBase64: string = "";
}


class DependentFieldMap {
  fieldId: number = 0;
  fieldValueMap: FieldValueMap = null
}

class FieldValueMap {
  fieldValue: number = 0;
  dependentFields: Array<number> = [];
}

class ServiceResponse{
  result:any;
  errorMessage:string;
  timeGenerated:string;
}

class ReserveCoverage{
  id:string;
  description:string;
}

class ReserveClaimant{
  id:string;
  desc:string;
  type:string;
}

class ClaimReserve{
  coverageTypeId:Number;
  reserveAmount:Number;
  processReserve:boolean;
  reserveCatId:Number;
  reserveTypeId:Number;
  infinityContactId:Number;
  claimantContact:Contact;
  uwRiskId:Number;
}

export class Contact{
  entityTypeId:Number;
  contactType:String;
  contactId:Number;
  nameTypeIds:Array<Number>;
}

class FVWithChildren {
  id: Number;
  fieldValue: string;
  isDefault: boolean;
  fieldId:Number;
  childfieldsandbehaviorattributes: Childfieldsandbehaviorattributes[];
}

class Childfieldsandbehaviorattributes {
  fieldVlaueId: Number;
  fieldid: Number;
  behavior: String;
}