import { Component } from '@angular/core';
import { ClaimService } from '../../shared/claimservice';
import { ActivatedRoute, Params } from '@angular/router';
import {ModalService} from 'FNOLAdminWeb/app/common/modal/';

@Component({
  selector: 'app-claim-component',
  templateUrl: './claim.component.html'
})
export class ClaimComponent {
  claimControlNumber: Number = 0;
  serviceResult: any;
  selectedScreen: any = {};
  claimStatus:String;
  claimStatusReason:String;
  claimRefNum:any;
  subCompanyCode:String;
  constructor(private claimService: ClaimService, private route: ActivatedRoute, private modalService: ModalService) {

  }

  modalText:any;
  modalHeader:any;
  showModal:boolean;

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if(this.route.snapshot.data['data'].error !== undefined){
          this.modalHeader = 'Error';
          this.modalText = this.route.snapshot.data['data'].error;
          this.showModal = true;
      }
      else{
        this.claimControlNumber = +this.route.snapshot.params['id'];
        this.serviceResult = this.route.snapshot.data['data'].result;
        this.claimControlNumber = this.serviceResult.header.controlNumber;
        this.claimStatus = this.serviceResult.header.status; 
        this.claimStatusReason = this.serviceResult.header.statusReason; 
        this.subCompanyCode = this.serviceResult.header.companyCode;    
        let errMsg = this.route.snapshot.data['data'].errorMessage;
        if(errMsg !== null && errMsg !== ''){
          this.modalHeader = 'Alert';
          this.modalText = errMsg;
          this.showModal = true;
        }    
      }
    });
  }

  ngAfterViewInit() {
    //this.modalText='Custom text';
    if(this.showModal)
      this.modalService.open('custom-modal-1'); 
  }

  closeModal(){
    this.showModal = false;
    this.modalService.close('custom-modal-1');
  }

  displayScreen(id) {
    //console.log('displayscreen: ' + JSON.stringify(this.serviceResult.screens.find(s => s.id == id)));
    if (this.serviceResult.screens.find(s => s.id == id) !== undefined) {
      let currentScreen = this.serviceResult.screens.find(s => s.id == id);
      if(currentScreen.description === 'VERIFY TRANSMIT DETAILS'){
        if(currentScreen.dataEntities.length === 0){
          this.showModalPopupMessage('Please check your Policy Number or Date of Loss before transmitting the claim.', 'Transmit');
        }
        else{
          //let policyIdField = currentScreen.dataEntities[0].fields.find(f => f.fieldDescription === 'Policy Id:');
          if(!currentScreen.canBeTransmitted){
            this.showModalPopupMessage('Please check your Policy Number or Date of Loss before transmitting the claim.', 'Transmit');
          }
        }
      }
      this.selectedScreen = this.serviceResult.screens.find(s => s.id == id);
    }
    else {
      this.selectedScreen = {
        id: 0,
        description: id
      };  
    }
  }

  onCantBeTransmitted($event){
    this.showModalPopup($event, 'Transmit');
  }

  onPolicyNumberUpdated($event){
    this.showModalPopup($event, 'Policy Updated');
  }

  onZipCodeUpdated($event){
    this.showModalPopup($event, 'ZipCode Updated');
  }

  onSendReportOfloss($event){
    this.showModalPopup($event, 'SendReportOfLoss');
  }

  sentAcknowledgement($event) {
    this.showModalPopup($event, 'Acknowledgement');
  }

  refreshScreen($event) {
    this.claimService.getClaim(this.claimControlNumber).subscribe(response => {
      this.serviceResult = (<GetClaimResponse>response).result;
      this.claimStatus = this.serviceResult.header.status;
      this.claimStatusReason = this.serviceResult.header.statusReason;
      if ((<GetClaimResponse>response).result.screens.find(s => s.id == this.selectedScreen.id) !== undefined) {
        this.selectedScreen = (<GetClaimResponse>response).result.screens.find(s => s.id == this.selectedScreen.id);
      }
      else {
        this.selectedScreen = {
          id: 0,
          description: this.selectedScreen.description
        };  
      }
      this.showModalPopup($event, 'Transmit');
     });
    }

    onSaveTransmitDetails($event){
      this.claimService.getClaim(this.claimControlNumber).subscribe(response => {
        this.serviceResult = (<GetClaimResponse>response).result;
        this.claimStatus = this.serviceResult.header.status;
        this.claimStatusReason = this.serviceResult.header.statusReason;
        if ((<GetClaimResponse>response).result.screens.find(s => s.id == this.selectedScreen.id) !== undefined) {
          this.selectedScreen = (<GetClaimResponse>response).result.screens.find(s => s.id == this.selectedScreen.id);
        }
        else {
          this.selectedScreen = {
            id: 0,
            description: this.selectedScreen.description
          };  
        }
        this.showModalPopup($event, 'Save');
       });
    }

    showModalPopupMessage(message, header){
      this.modalHeader = header;
      this.modalText = message;
      this.modalService.open('custom-modal-1'); 
      this.showModal = true;
    }

    showModalPopup($event, header){
      if($event !== undefined){
        if($event.event.message !== ''){
          this.modalHeader = header;
          this.modalText = $event.event.message;
        }
        else if($event.event.error !== ''){
          this.modalHeader = 'Error';
          this.modalText = $event.event.error;
        }
        this.modalService.open('custom-modal-1'); 
        this.showModal = true;
        
      }
    }
  }

  class GetClaimResponse{
    result:any;
  }
