import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: ['ClientApp/src/assets/styles.css']
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean;
  title = 'app';
  error: Error;
  
  constructor(public oktaAuth: OktaAuthService, public router: Router) {
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
    (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );
  }  
  
  ngOnInit(): void {
    
  }

  async login() {
    try {
      await this.oktaAuth.signInWithRedirect();
    } catch (err) {
      console.error(err);
      this.error = err;
    }
  }

    async logout() {
    // Terminates the session with Okta and removes current tokens.
    await this.oktaAuth.signOut();
    this.router.navigateByUrl('/');
    }
}

