import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ServiceBase } from "./servicebase";
import { SessionService } from "./sessionservice";

@Injectable()
export class StateOrProvincesService extends ServiceBase {
  stateOrProvincesServiceUrl = '';
  constructor(private http: HttpClient, private sessionService:SessionService) {
    super();
    this.stateOrProvincesServiceUrl = this.apiBaseUrl +  'stateorprovinces/';
  }

  get(countryCode){
    const params = new HttpParams()
                        .set('userId', this.sessionService.userId);
    return this.http.get(this.stateOrProvincesServiceUrl + countryCode, {params});
  }
}
