import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import{ServiceBase} from './servicebase'
@Injectable()
export class HistoryService extends ServiceBase {
  historyServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.historyServiceUrl = this.apiBaseUrl + 'history';
  }
  
  getHistory(id) {
    return this.http.get(this.historyServiceUrl + '/' + id);
  }
}
