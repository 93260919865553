import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: `
  <input style="width: 100%;" type="checkbox" class="form-check-input" (change)="onProcessChange($event)" [(ngModel)]="process"/>          `
})
export class ReserveProcessRendererComponent implements AgRendererComponent {
  params: any;
  process:boolean;
  agInit(params: any): void {
    this.params = params;
    this.process = this.params.data.process;
    //console.log('Ag Grid: ' + JSON.stringify(this.params.data, this.getCircularReplacer()));
  }

  onProcessChange(event){
    //console.log('parent grid: ' + JSON.stringify(this.params.context.componentParent.claimId,  this.getCircularReplacer())); 
    let claimReserve = this.params.context.componentParent.claimReserves[this.params.rowIndex];
    if(claimReserve !== undefined){
      claimReserve.processReserve = this.process;
    }
    else{
      this.params.context.componentParent.claimReserves[this.params.rowIndex] = {processReserve: this.process};
    }
    //console.log('event: ' + JSON.stringify(this.params.context.componentParent.claimReserves,  this.getCircularReplacer())); 
  }

  refresh(params: any): boolean {
    return false;
  }

  /*getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };*/
}
