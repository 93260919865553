import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import{CreateNewClaimService} from '../shared/createnewclaimservice';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../common/modal';

@Component({
  selector: 'app-newclaim-component',
  templateUrl: './newclaim.component.html',
  styleUrls: ['newclaim.component.css']
})
export class NewClaimComponent {
  zipCode: Number;
  policyNumber: String;
  formdata;
  modalText:any;
  modalHeader:any;
  showModal:boolean;
  constructor(private route: ActivatedRoute, private router: Router, 
    private createNewClaimService:CreateNewClaimService, private modalService: ModalService) {

  }
  
  ngOnInit() { 
     this.formdata = new FormGroup({ 
      policyNumber: new FormControl("", [Validators.minLength(6)]),
      zipCode: new FormControl("", [this.noWhitespaceValidator, Validators.pattern('^[0-9]{2,5}(:|\\-)?[0-9]{3,4}')])
     }); 
  } 

  closeModal(){
    this.showModal = false;
    this.modalService.close('custom-modal-1');
  }

  submitForm(data){
    if(this.formdata.valid){
      let createNewClaimModel = {policyNumber : data.policyNumber, zipCode: data.zipCode};
      this.createNewClaimService.create(createNewClaimModel).subscribe((response:NewClaimServiceResponse) => { 
        this.router.navigate(['/claim/' + response.id]);
      }, (error:any) => {
        this.showModalPopup(error.error?.message, 'Error');
      });
    }
  }

  showModalPopup(message, header){
    this.modalHeader = header;
    this.modalText = message;
    this.modalService.open('custom-modal-1'); 
    this.showModal = true;
  }

  public noWhitespaceValidator(control: FormControl) {
    if(control.value?.length > 0){
      return (control.value || '').trim().length? null : { 'whitespace': true };   
    }    
    return null;
  }

}

class NewClaimServiceResponse{
    id:Number;
}