import { ElementRef, Optional, Directive } from "@angular/core";
import { NgControl, NgModel } from "@angular/forms";

@Directive({
  selector: '[ngModel], [formControl], [formControlName]', // or 'input, select, textarea' - but then your controls won't be handled and also checking for undefined would be necessary
})
export class NativeElementInjectorDirective {
  constructor(private el: ElementRef, private control: NgControl, @Optional() private model: NgModel) {
  }
  ngOnInit() {
    if (!!this.model)
      (<any>this.model.control).nativeElement = this.el.nativeElement;
    else
      (<any>this.control).nativeElement = this.el.nativeElement;
  }
}
