import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class SaveTransmitDetailsService extends ServiceBase {
  saveTransmitDetailsServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.saveTransmitDetailsServiceUrl = this.apiBaseUrl +  'savetransmitdetails';
  }

  save(createInfinityClaimModel){
    return this.http.post(this.saveTransmitDetailsServiceUrl, createInfinityClaimModel);
  }
}
