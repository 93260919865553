import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class SessionService extends ServiceBase {
  userId:string = '';
  userOrg:string = '';
  sessionServiceUrl = this.apiBaseUrl + 'Session?userId=';
  constructor(private http: HttpClient) {
    super();
  }

  getUserOrg(){
    let url = this.sessionServiceUrl + this.userId;
    let org = '';
    this.http.get(url).subscribe((response:SessionServiceResponse) =>{
      this.userOrg = response.organization;
    });
  }
}

class SessionServiceResponse
{
  organization:string;
}