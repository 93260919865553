import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: `
  <select style="width: 100%;" (change)="onCatChange($event)" [(ngModel)]="reserveCategory">
      <option *ngFor="let reserveCat of reserveCats" [ngValue]="reserveCat.reserveCatId">{{reserveCat.reserveCat}}</option>
    </select>
          `
})
export class ReserveCatRendererComponent implements AgRendererComponent {
  params: any;
  reserveCats:Array<Object>;
  reserveCategory:any;
  agInit(params: any): void {
    this.params = params;
    this.reserveCats = params.data.reserveCat;
    this.reserveCategory = params.data.reserveCategory;
  }

  onCatChange(event){
    //console.log('parent grid: ' + JSON.stringify(this.params.context.componentParent.claimId,  this.getCircularReplacer())); 
    let claimReserve = this.params.context.componentParent.claimReserves[this.params.rowIndex];
    if(claimReserve !== undefined){
      claimReserve.reserveCatId = this.reserveCategory;
    }
    else{
      this.params.context.componentParent.claimReserves[this.params.rowIndex] = {reserveCatId: this.reserveCategory};
    }
    //console.log('event: ' + JSON.stringify(this.params.context.componentParent.claimReserves,  this.getCircularReplacer())); 
  }

  refresh(params: any): boolean {
    return false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
}
