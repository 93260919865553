import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class LossDetailService extends ServiceBase {
  lossdetailServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.lossdetailServiceUrl = this.apiBaseUrl +  'lossdetail/';
  }

  put(claimId, lossDate){
    return this.http.put(this.lossdetailServiceUrl, {'claimId': claimId, 'lossDate' : lossDate});
  }
}
