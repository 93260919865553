<div class="row">
  <div class="col-md-12 bg-light">
    <h3>Search Results</h3>
    <hr />
    <p>Showing {{rowData?.length}} Results</p>
  </div>
  <div class="col-md-12">
    <hr />
  </div>
</div>
  <div *ngIf="this.rowData.length > 0" class="row ag-theme-alpine">
    <div class="col-md-12">
      <ag-grid-angular domLayout="autoHeight" class="ag-theme-alpine"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
