import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class ZipCodeLookupService extends ServiceBase {
  zipCodeLookupServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.zipCodeLookupServiceUrl = this.apiBaseUrl +  'zipcodelookup/';
  }

  get(zipcode){
    return this.http.get(this.zipCodeLookupServiceUrl + zipcode);
  }
}
