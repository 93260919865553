import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class SendReportOfLossService extends ServiceBase {
    sendReportOfLossUrl = '';
    constructor(private http: HttpClient) {
        super();
        this.sendReportOfLossUrl = this.apiBaseUrl + 'SendReportOfLoss';
      }
    
      send(id) {
        return this.http.post(this.sendReportOfLossUrl + '/' + id, {});
      }
}