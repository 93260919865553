import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class SearchService extends ServiceBase {
  claimsApiSearchUrl = '';
  lastSearchResult: any;
  constructor(private http: HttpClient) {
    super();
    this.claimsApiSearchUrl = this.apiBaseUrl + 'search';
  }

  search(searchParameters) {
    return this.http.post(this.claimsApiSearchUrl, searchParameters);
  }
}
