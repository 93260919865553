import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class CreateInfinityClaimService extends ServiceBase {
  createInfinityClaimServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.createInfinityClaimServiceUrl = this.apiBaseUrl +  'createinfinityclaim';
  }

  create(createInfinityClaimModel){
    return this.http.post(this.createInfinityClaimServiceUrl, createInfinityClaimModel);
  }
}
