import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import{ServiceBase} from './servicebase'
@Injectable()
export class CommunicationService extends ServiceBase {
  commServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.commServiceUrl = this.apiBaseUrl + 'communications';
  }
  
  sendAcknowledgement(ack) {
    return this.http.post(this.commServiceUrl, ack);
  }
  
  getAcknowledgementBody(id) {
    return this.http.get(this.commServiceUrl + '/' + id);
  }
}
