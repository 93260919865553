import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ClaimComponent } from './claim//claimcontainer/claim.component';
import { ClaimService } from './shared/claimservice';
import { ClaimResolver } from './shared/claim.resolver.service'
import { ClaimHeaderComponent } from './claim/claimheader/claimheader.component'
import { ClaimSidebarComponent } from './claim/claimsidebar/claimsidebar.component'
import { ClaimScreenDetailComponent } from './claim/claimscreendetail/claimscreendetail.component'
import { NativeElementInjectorDirective } from './directives/nativeelementinjector.directive'
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SearchComponent } from './search/search.component';
import { SearchResultComponent } from './searchresult/searchresult.component';
import { SearchService } from './shared/searchservice';
import { AgGridModule } from 'ag-grid-angular';
import { RouterLinkRendererComponent } from './searchresult/routerlinkrenderer.component'
import {ServiceBase} from './shared/servicebase'
import {CommunicationService} from './shared/communicationservice'
import{TransmitService} from './shared/transmitservice'
import{CreateInfinityClaimService} from './shared/createinfinityclaimservice'
import{StateOrProvincesService} from './shared/stateorprovincesservice'
import{RiskCoveragesService} from './shared/riskcoveragesservice'
import{DocumentService} from './shared/documentservice'
import{LossDetailService} from './shared/lossdetailservice'
import{DatePipe} from '@angular/common'
import{GridApi} from 'ag-grid-community'
import {OKTA_CONFIG, OktaAuthModule, OktaCallbackComponent, OktaAuthGuard} from '@okta/okta-angular';
import { ModalModule } from './common/modal';
import { ReserveCatRendererComponent } from './claim/claimscreendetail/gridcomponents/reservecatrenderer.component';
import { ReserveTypeRendererComponent } from './claim/claimscreendetail/gridcomponents/reservetyperenderer.component';
import { ReserveAmountRendererComponent } from './claim/claimscreendetail/gridcomponents/reserveamountrenderer.component';
import { ReserveProcessRendererComponent } from './claim/claimscreendetail/gridcomponents/reserveprocessrenderer.component';
import { ReserveCoverageRendererComponent } from './claim/claimscreendetail/gridcomponents/reservecoveragerenderer.component';
import { ReserveClaimantRendererComponent } from './claim/claimscreendetail/gridcomponents/reserveclaimantrenderer.component';
import { NewClaimComponent } from './newclaim/newclaim.component';
import { CreateNewClaimService } from './shared/createnewclaimservice';
import { SendReportOfLossService } from './shared/sendreportofloss.service';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { SafeResource } from './pipes/safeResource.pipe';
import { SessionService } from './shared/sessionservice';
import { ClaimPolicyService } from './shared/claimpolicyservice';
import { LogService } from './shared/logservice';
import { HistoryService } from './shared/historyservice';
import { SaveTransmitDetailsService } from './shared/savetransmitdetailsservice';
import { ClaimZipCodeService } from './shared/claimzipcodeservice';

const config = {
  issuer: 'https://hcc-dev.oktapreview.com/oauth2/aus10iwn7eqUx5HDL0h8', // 'https://hcc-dev.oktapreview.com/oauth2/default',
  redirectUri: 'https://fnoladminuat.tmhcc.com/login/callback', //'http://localhost:4200/login/callback',
  clientId: '0oazc4d2ghAP4RXD20h7', //'0oa10iwbir3qeMPyo0h8', 
  pkce: false
  }


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ClaimComponent,
    ClaimHeaderComponent,
    ClaimSidebarComponent,
    ClaimScreenDetailComponent,
    SearchComponent,
    SearchResultComponent,
    NativeElementInjectorDirective,
    RouterLinkRendererComponent,
    ReserveCatRendererComponent,
    ReserveTypeRendererComponent,
    ReserveAmountRendererComponent,
    ReserveProcessRendererComponent,
    ReserveCoverageRendererComponent,
    ReserveClaimantRendererComponent,
    NewClaimComponent,
    PhoneMaskDirective,
    SafeResource
  ],
  imports: [
    OktaAuthModule,
    ModalModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgHttpLoaderModule.forRoot(),
    AgGridModule.withComponents(null),
    RouterModule.forRoot([
    { path: 'claim/:id', component: ClaimComponent, resolve: { data: ClaimResolver } , canActivate: [ OktaAuthGuard ] },
    { path: '', component: SearchComponent, pathMatch: 'full', canActivate: [OktaAuthGuard] },
    { path: 'searchresult', component: SearchResultComponent },
    { path: 'login/callback', component: OktaCallbackComponent, pathMatch: 'full' },
    { path: 'newclaim', component: NewClaimComponent }
], { relativeLinkResolution: 'legacy' })
  ],
  providers: [{provide: OKTA_CONFIG, useValue: config}, OktaAuthGuard, GridApi, DatePipe, ClaimService, ClaimResolver, 
                  LossDetailService, DocumentService, SearchService, CommunicationService, TransmitService, CreateInfinityClaimService, 
                  StateOrProvincesService, RiskCoveragesService, CreateNewClaimService, SendReportOfLossService, ClaimPolicyService,
                  SessionService, LogService, HistoryService, SaveTransmitDetailsService, ClaimZipCodeService, ServiceBase],
  bootstrap: [AppComponent]
})
export class AppModule { }
