import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class RiskCoveragesService extends ServiceBase {
  riskCoveragesServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.riskCoveragesServiceUrl = this.apiBaseUrl +  'riskcoverages/';
  }

  get(policyTxnId, lossDate, riskId){
    return this.http.get(this.riskCoveragesServiceUrl + policyTxnId + '/' + lossDate + '/' + riskId);
  }
}
