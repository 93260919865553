import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class DocumentService extends ServiceBase {
  documentServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.documentServiceUrl = this.apiBaseUrl +  'document/';
  }

  delete(dataEntityId, fieldId){
    return this.http.delete(this.documentServiceUrl + dataEntityId + '/' + fieldId);
  }
}
