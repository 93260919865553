
<div class="alert alert-warning" role="alert" *ngIf="openClaimsMessage !== ''">
  <p aria-live="polite">{{openClaimsMessage}}</p>
</div>
<div class="col-md-12 border box-shadow bg-light">
  <div class="row form-group pt-4">
    <div class="col-md-3">
      <h5>Reference Number</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.controlNumber }}</p>
    </div>
    <div class="col-md-3">
      <h5>Policy Number</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.policyNumber }}</p>
    </div>
  </div>
  <hr />
  <div class="row form-group">
    <div class="col-md-3">
      <h5>Claim Number</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.claimRefNumber }}</p>
    </div>
    <div class="col-md-3">
      <h5>Insured Name</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.insuredName }}</p>
    </div>
  </div>
  <hr />
  <div class="row form-group">
    <div class="col-md-3">
      <h5>Status</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.status }}</p>
    </div>
    <div class="col-md-3">
      <h5>Transmitted</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.transmitted === false ? 'No' : 'Yes' }}</p>
    </div>
  </div>
  <hr />
  <div class="row form-group">    
    <div class="col-md-3">
      <h5>Transmitted By</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.transmittedBy }}</p>
    </div>
    <div class="col-md-3">
      <h5>Submit Source</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.submitSource }}</p>
    </div>
  </div>
  <hr />
  <div *ngIf="claimheader.companyCode == 'UAV' || claimheader.companyCode == 'AAV'" class="row form-group">
    <div class="col-md-3">
      <h5>Risk Description</h5>
    </div>
    <div class="col-md-3">
      <p aria-live="polite">{{ claimheader.riskDescription }}</p>
    </div>
  </div>
</div>

