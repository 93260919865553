import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component({
  template: '<a [routerLink]="claimDetailLink">{{params.data.claimId}}</a>'
})
export class RouterLinkRendererComponent implements AgRendererComponent {
  params: any;
  claimDetailLink: any;
  agInit(params: any): void {
    this.params = params;
    this.claimDetailLink = '/claim/' + this.params.data.claimId;
    //console.log('Ag Grid: ' + JSON.stringify(this.params.data.claimId, this.getCircularReplacer()));
  }

  refresh(params: any): boolean {
    return false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
}
